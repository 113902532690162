class sliderSmallSectionComponent extends Component {

    static name() {
        return "sliderSmallSectionComponent";
    }

    static componentName() {
        return "sliderSmallSectionComponent";
    }

    getProps() {
        return  ['items','config'];
    }

    data() {
        return {
            itemsPerPage:6,
            initPage:0,
            animate:true,
            interval:8000,
            animateEvent:null,
            isMobile: $(window).width() < 768,
            isTablet: $(window).width() < 992,
        };
    }

    mounted() {
        return function () {
            this.animateCallback.bind(this);
            this.$nextTick(function () {
                if(this.animate)
                    this.animateCallback();
                
                if (this.isMobile) {
                    this.itemsPerPage = 3;
                } else if (this.isTablet) {
                    this.itemsPerPage = 4;
                }
            });
            
        };
    }

    getComputed() {
        return {
            maxPages:function (){
                if(Array.isArray(this.items))
                    return this.items.length;
                return 0;
            },
            controlActivate:function (){
                return this.maxPages > this.itemsPerPage;
            }
        };
    }

    getMethods() {
        return {
            itemCarouselMoveLeft:this.itemCarouselMoveLeft,
            itemCarouselMoveRight:this.itemCarouselMoveRight,
            animateCallback:this.animateCallback,
            animateStop:this.animateStop,
            isAppRouter:this.isAppRouter
        };
    }

    itemCarouselMoveLeft(){
        if(this.initPage - 1 >= 0)
            this.initPage -= 1;
    }

    itemCarouselMoveRight(){
        if(this.initPage + 1 + this.itemsPerPage > this.maxPages)
            this.initPage = 0;
        else
            this.initPage += 1;

    }

    animateCallback(){
        if(this.controlActivate) {
            console.log("Restart Animation");
            this.itemCarouselMoveRight();
            this.animateEvent = setTimeout(this.animateCallback, this.interval);
        }
    }

    animateStop(){
        console.log("Stop Animation",this.animateEvent);
        clearTimeout(this.animateEvent);
    }

    isAppRouter(item) {
        if(item.fields.ItemLink && item.fields.ItemLink.length > 0 && (item.fields.ItemLink[0] === '#' || item.fields.ItemLink[0] === '.' || item.fields.ItemLink[0] === '/')){
            console.log('APP LINK ',item.fields.ItemLink);
            return true;
        }
        return false;
    }

    getTemplate() {
        return `<div class="row col-12 justify-content-center">
                   <template v-for="(item,index) in items.slice(initPage,initPage+itemsPerPage)">
                       <template v-if="item.fields.ItemPhotoUri">
                            <div class="col-4 col-md-3 col-lg-2">
                               <template v-if="item.fields.ItemLink">
                                   <template v-if="isAppRouter(item)">
                                       <router-link :to="item.fields.ItemLink.replace('#','')">
                                            <img :src="item.fields.ItemPhotoUri" alt="Sider" class="d-block w-100">
                                       </router-link>
                                   </template>
                                   <template v-else>
                                        <a target="_blank" :href="item.fields.ItemLink" >
                                            <img :src="item.fields.ItemPhotoUri" alt="Sider" class="d-block w-100" >
                                        </a>
                                   </template>
                               </template>
                               <template v-else>
                                    <img :src="item.fields.ItemPhotoUri" alt="Sider" class="d-block w-100">
                               </template>
                            </div>
                       </template>
                   </template>
                    <div class="row justify-content-center itemcard-control-container" v-if="controlActivate">
                          <a class="itemcard-control itemcard-control-prev" href="javascript:void(0)" :title="tr('Previous')" @click="itemCarouselMoveLeft" style="wi">
                              <icon  name="chevron-left"></icon>
                          </a>
                          <a class="itemcard-control itemcard-control-next" href="javascript:void(0)" :title="tr('Next')" @click="itemCarouselMoveRight">
                            <icon name="chevron-right"></icon>
                          </a>
                      </div>
                </div>`;
    }
}

sliderSmallSectionComponent.registerComponent();